import { getSrc } from "gatsby-plugin-image"
import { ArticleDetails } from "../article-types"
import { ContentfulTeacher, GuidePageQuery } from "../../../../graphql/types"
import { mapTeachers } from "@core/mappers/map-teachers"
import { mapMetadata } from "@core/mappers/map-metadata"
import { mapNestedTopics } from "@core/mappers/map-nested-topics"
import { truncate } from "@core/utils"

export interface QuoteDetails {
  body: string
  author: string
  source: string
  location: string
}

export const mapSourceGuideToView = (
  query: GuidePageQuery
): [ArticleDetails, QuoteDetails[] | undefined] => {
  const content = query.contentfulGuide!

  const guideSummary =
    content?.childContentfulGuideSummaryTextNode?.childMarkdownRemark
      ?.rawMarkdownBody || ""

  const guideExplanation =
    content?.childContentfulGuideExplanationTextNode?.childMarkdownRemark
      ?.rawMarkdownBody || ""

  const contentfulId = content.contentful_id

  const id = content.id

  const teachers = mapTeachers(content.teachers as Partial<ContentfulTeacher>[])

  const timeToRead =
    content?.childContentfulGuideExplanationTextNode?.childMarkdownRemark
      ?.timeToRead

  const title = content?.name || ""

  const topics = mapNestedTopics(content.primaryTopic)

  const date = content?.date

  const description =
    content.childContentfulGuideSummaryTextNode?.childMarkdownRemark?.plainText

  const metadata = mapMetadata(content.metadata, {
    title,
    description: description && truncate(description),
    image: getSrc(content.headerImage?.metadataImage)
  })

  const quotes = content.quotations?.map((quote) => ({
    body:
      quote!.childContentfulGuideQuotationQuoteTextNode!.childMarkdownRemark!
        .rawMarkdownBody || "",
    location:
      quote!.childContentfulGuideQuotationQuoteLocationTextNode!
        .childMarkdownRemark!.rawMarkdownBody || "",
    author: quote!.quoteAuthor || "",
    source: quote!.quoteSource?.quoteSource || ""
  }))

  return [
    {
      body: "",
      guideSummary,
      guideExplanation,
      contentfulId,
      id,
      date,
      metadata,
      teachers,
      timeToRead,
      title,
      topics,
      productLinkExternal: null,
      primaryTopic: content.primaryTopic
    },
    quotes
  ]
}

import React, { FunctionComponent } from "react"
import { graphql } from "gatsby"
import { GuidePageQuery } from "../../../graphql/types"
import { PageProps } from "../../types/page"
import { Page } from "../../components/page/page"
import { PageBreadcrumbs } from "../../core/breadcrumbs"
import { mapSourceGuideToView } from "../../features/articles/mappers/guide-mapper"
import { ArticleDetailsPage } from "../../features/articles/components/article-details-page/article-details-page"

export const GuideTemplate: FunctionComponent<PageProps<GuidePageQuery>> = ({
  data,
  ...props
}) => {
  const [guide, quote] = mapSourceGuideToView(data!)

  return (
    <Page
      {...props}
      metadata={guide.metadata}
      pageOptions={{
        headerVariant: "cream",
        breadcrumbs: [
          ...PageBreadcrumbs.Guides,
          {
            label: guide.title,
            link: `${props.uri}`
          }
        ]
      }}
    >
      <ArticleDetailsPage article={guide} quote={quote} pageType="guide" />
    </Page>
  )
}

export default GuideTemplate

export const query = graphql`
  query GuidePage($slug: String) {
    contentfulGuide(slug: { eq: $slug }) {
      ...GuideDetails
    }
  }
`
